var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c(
              "b-link",
              { staticClass: "brand-logo" },
              [
                !_vm.school.logo
                  ? _c("vuexy-logo")
                  : _c("img", { attrs: { src: _vm.school.logo, alt: "" } }),
              ],
              1
            ),
            _c("b-card-title", { staticClass: "mb-1 text-center" }, [
              _vm._v(" Confirmação de cadastro "),
            ]),
            _c("b-card-text", { staticClass: "mb-2 text-center" }, [
              _vm._v(" Cadastre a sua senha. "),
            ]),
            _c(
              "b-form",
              {
                staticClass: "auth-reset-password-form mt-3",
                attrs: { method: "POST" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.confirmation.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Nova senha",
                            "label-for": "password",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "input-group-merge",
                              class: {
                                "is-invalid": _vm.$v.user.password.$error,
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control-merge",
                                class: {
                                  "is-invalid": _vm.$v.user.password.$error,
                                },
                                attrs: {
                                  id: "password",
                                  type: _vm.password1FieldType,
                                  name: "password",
                                  placeholder: "············",
                                },
                                model: {
                                  value: _vm.user.password,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "password",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "user.password",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: { icon: _vm.password1ToggleIcon },
                                    on: {
                                      click: _vm.togglePassword1Visibility,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "confirm",
                            label: "Confirmar senha",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "input-group-merge",
                              class: {
                                "is-invalid": _vm.$v.user.repeatPassword.$error,
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control-merge",
                                class: {
                                  "is-invalid":
                                    _vm.$v.user.repeatPassword.$error,
                                },
                                attrs: {
                                  id: "confirm",
                                  type: _vm.password2FieldType,
                                  name: "confirm",
                                  placeholder: "············",
                                },
                                model: {
                                  value: _vm.user.repeatPassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "repeatPassword",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "user.repeatPassword",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: { icon: _vm.password2ToggleIcon },
                                    on: {
                                      click: _vm.togglePassword2Visibility,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-button",
                  {
                    attrs: {
                      block: "",
                      type: "submit",
                      disabled: _vm.loading,
                      variant: "success",
                    },
                  },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          [
                            _c("b-spinner", {
                              attrs: { small: "", variant: "light" },
                            }),
                            _vm._v(" Verificando... "),
                          ],
                          1
                        )
                      : _c("div", [_vm._v("Cadastrar senha")]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "text-center mt-2" },
              [
                _c(
                  "b-link",
                  { attrs: { to: { name: "auth-user-login" } } },
                  [
                    _c("feather-icon", { attrs: { icon: "ChevronLeftIcon" } }),
                    _vm._v(" Voltar para login "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("p", { staticClass: "text-center mb-0 mt-2" }, [
          _vm._v(
            " " +
              _vm._s(this.school.company_name) +
              " - CNPJ " +
              _vm._s(this.school.document) +
              " "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }