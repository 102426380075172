<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo v-if="!school.logo" />
          <img v-else :src="school.logo" alt="" />
        </b-link>

        <b-card-title class="mb-1 text-center">
          Confirmação de cadastro
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Cadastre a sua senha.
        </b-card-text>

        <b-form
          method="POST"
          class="auth-reset-password-form mt-3"
          @submit.prevent="confirmation"
        >
          <div class="form-row">
            <div class="col-6">
              <b-form-group label="Nova senha" label-for="password">
                <b-input-group class="input-group-merge" :class="{ 'is-invalid': $v.user.password.$error }">
                  <b-form-input
                    id="password"
                    v-model.trim="user.password"
                    :type="password1FieldType"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                    :class="{ 'is-invalid': $v.user.password.$error }"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                label-for="confirm"
                label="Confirmar senha"
              >
                <b-input-group class="input-group-merge" :class="{ 'is-invalid': $v.user.repeatPassword.$error }">
                  <b-form-input
                    id="confirm"
                    v-model.trim="user.repeatPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    name="confirm"
                    placeholder="············"
                    :class="{ 'is-invalid': $v.user.repeatPassword.$error }"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <b-button block type="submit" :disabled="loading" variant="success">
            <div v-if="loading">
              <b-spinner small variant="light" /> Verificando...
            </div>
            <div v-else>Cadastrar senha</div>
          </b-button>
        </b-form>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-user-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para login
          </b-link>
        </p>
      </b-card>
      <p class="text-center mb-0 mt-2">
        {{ this.school.company_name }} - CNPJ {{ this.school.document }}
      </p>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useJwt from "@/auth/jwt/useJwt";
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      user: {
        password: '',
        repeatPassword: '',        
      },
      password1FieldType: 'password',
      password2FieldType: 'password',
    };
  },
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(6),
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    ...mapState('School', ['school']),
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    confirmation() {
      this.$v.$touch();

      if (!this.$v.$error) {
        this.loading = true;
        this.$store.dispatch('AuthUser/newPassword', {
            password: this.user.password,
            token: this.$route.params.token,
          }).then(() => {
            this.notify(
              'Senha alterada com sucesso',
              'UserCheckIcon',
              'success',
              'Efetue o login para acessar o dashboard.',
            );
            this.$router.push({ name: 'auth-user-login' });
          })
          .catch(() => {
            this.loading = false;
            this.notify(
              'Ops, algo de errado aconteceu',
              'CoffeeIcon',
              'danger',
              'Não foi possível cadastrar sua senha. Talvez o link tenha expirado.',
            );
          });
      } else {
        this.notify(
          'Ops, você esqueceu de algo',
          'XIcon',
          'danger',
          'Você deixou de preencher algum campo obrigatório.',
        );
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password';
    },
    notify(_title, _icon, _variant, _text, _time) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: _title,
            icon: _icon,
            variant: _variant,
            text: _text,
          },
        },
        {
          position: 'top-center',
          timeout: _time | 4000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          hideProgressBar: true,
          showCloseButtonOnHover: true,
        }
      );
    },
  },
};
</script>

<style lang='scss'>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
